$('body').on('submit', 'form', function (e) {
    if ($(this).hasClass('ajax-form')) {
        e.preventDefault();

        let self = this;

        let submitButton = $(self).find('button[type=submit]').first();
        let oldSubmitMessage = submitButton.val() ? submitButton.val() : submitButton.text();
        if (submitButton) {
            submitButton.html(
                '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lädt...'
            );
        }

        $('.form-control').removeClass('is-invalid');
        $('.card').removeClass('accordion-warning');
        $('.invalid-feedback').html('');
        $('.invalid-feedback').css('display', 'none');

        let action = $(self).attr('action');
        let formData = new FormData(this);
        let method = $(self).attr('method');
        if (!method) {
            method = "post";
        }

        $.ajax({
            url: action,
            method: method,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            complete: function (result) {
                if (submitButton) {
                    submitButton.val(oldSubmitMessage).text(oldSubmitMessage);
                }
            },
            error: function (result) {
                let data = result.responseJSON;
                let errors = data.errors;
                let i = 0;
                let top = -1;
                if (!jQuery.isEmptyObject(errors)) {
                    for (let field in errors) {
                        if (!errors.hasOwnProperty(field)) continue;
                        let message = errors[field][0];
                        let field_id = field.replaceAll(".", "_");
                        let inputField = $('#'+field_id);
                        // let inputField = $('[name="' + field.replaceAll(".", "_") + '"');

                        inputField.closest('.form-group').find('.form-control').addClass('is-invalid');
                        inputField.closest('.form-group').find('.privacy-checkbox').addClass('is-invalid');
                        inputField.closest('.form-group').find('.invalid-feedback').html(message);
                        inputField.closest('.form-group').find('.invalid-feedback').css('display', 'block');
                        inputField.closest('.card').addClass('accordion-warning');

                        if (top < 0 || top > inputField.closest('.card').offset().top) {
                            top = inputField.closest('.card').offset().top;
                        }
                        i++;
                    }
                    if (top > 0) {
                        $('html, body').animate({
                            scrollTop: top - 200,
                        }, 200);
                    }
                }
            },
            success: function (result) {
                if (result.hasOwnProperty('redirect')) {
                    document.location.href = result.redirect;
                }
            }
        });
    }
});
